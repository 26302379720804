<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar">
        <CurrentSearch v-if="isDashboard"></CurrentSearch>

        <div class="topbar-item mr-1" v-if="isUserPage()">
            <!--begin::Button-->
            <router-link
                :to="{ name: 'AddUser' }"
                class="btn btn-light-primary font-weight-bold ml-2"
            >
                <span class="svg-icon svg-icon-md svg-icon-primary hover-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg
                        class="mr-2"
                        src="/media/svg/icons/Communication/Add-user.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->User
                </span>
            </router-link>
            <!--end::Button-->
        </div>
        <div class="topbar-item mr-1">
            <router-link to="/advanced-search" custom v-slot="{ href, navigate }">
                <a :href="href" @click="navigate">
                    <b-button>Cautare Avansata</b-button>
                </a>
            </router-link>
        </div>

        <AddConversationModal
            v-if="departments && departments.length > 0"
        ></AddConversationModal>

        <!--begin: Quick panel toggle -->
        <KTQuickPanel></KTQuickPanel>
        <!--end: Quick panel toggle -->

        <!--begin: User Bar -->
        <KTQuickUser></KTQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
    .dropdown-toggle {
        padding: 0;
        &:hover {
            text-decoration: none;
        }

        &.dropdown-toggle-no-caret {
            &:after {
                content: none;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 0;
        outline: none;
        .b-dropdown-text {
            padding: 0;
        }
    }
    a:hover .svg-icon.hover-icon-white svg g [fill] {
        fill: #fff !important;
    }
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import i18nService from "@/core/services/i18n.service.js";
import AddConversationModal from "@/view/pages/dashboard/modals/AddConversationModal.vue";
import CurrentSearch from "@/view/pages/dashboard/CurrentSearch.vue";
import { mapGetters } from "vuex";

export default {
    name: "KTTopbar",
    data() {
        return {
            languageFlag: "",
            languages: i18nService.languages,
        };
    },
    watch: {
        isDashboard() {
            if (!this.isDashboard) {
                this.$store.dispatch("mails/filterMails", false);
            }
        },
    },
    components: {
        KTQuickUser,
        KTQuickPanel,
        AddConversationModal,
        CurrentSearch,
    },
    methods: {
        onLanguageChanged() {
            this.languageFlag = this.languages.find((val) => {
                return val.lang === i18nService.getActiveLanguage();
            }).flag;
        },
        isUserPage() {
            const getUrlPathSegments = new URL(window.location.href).pathname
                .split("/")
                .filter(Boolean);
            return getUrlPathSegments[0] && getUrlPathSegments[0] == "users"
                ? true
                : false;
        },
    },
    computed: {
        getLanguageFlag() {
            return this.onLanguageChanged();
        },
        isDashboard() {
            return this.$route.path.split("/")[1] === "dashboard";
        },
        ...mapGetters("departments", ["departments"]),
    },
    created() {
        this.isUserPage();
        if (this.$store.getters["departments/departments"].length === 0) {
            this.$store.dispatch("departments/getDepartments");
        }
    },
};
</script>
