var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbar-item"},[_c('div',{staticClass:"btn btn-icon btn-clean btn-lg mr-1",attrs:{"id":"kt_quick_panel_toggle"}},[_c('span',{staticClass:"svg-icon svg-icon-xl svg-icon-danger"},[_c('span',{staticClass:"svg-icon svg-icon-2hx",class:_vm.getUnreadCount > 0 ? 'svg-icon' : 'svg-icon-muted'},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Notifications1.svg"}})],1)])]),_c('div',{ref:"kt_quick_panel",staticClass:"offcanvas offcanvas-right pt-5 pb-10",staticStyle:{"overflow":"hidden"},attrs:{"id":"kt_quick_panel"}},[_c('div',{staticClass:"offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"},[_c('ul',{staticClass:"nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10 justify-content-between",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"false"}},[_vm._v(" Notificari "),(_vm.getUnreadCount > 0)?_c('span',[_vm._v("("+_vm._s(_vm.getUnreadCount)+")")]):_vm._e()])]),(_vm.getUnreadCount > 0)?_c('span',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",on:{"click":_vm.markAllAsRead}},[_vm._v("Marcheaza toate ca citite")])]):_vm._e()]),_vm._m(0)]),_c('div',{staticClass:"offcanvas-content px-10"},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"scroll pr-7 mr-n7",attrs:{"id":"kt_quick_panel_notifications"}},[_c('div',{staticClass:"navi navi-icon-circle navi-spacer-x-0"},[_vm._l((_vm.getNotifications),function(item,i){return [_c('router-link',{key:i,staticClass:"navi-item",attrs:{"to":{
                                    name: 'conversation',
                                    params: { id: item.conversation_id },
                                }},nativeOn:{"click":function($event){return _vm.markAsRead(item.conversation_id)}}},[_c('div',{staticClass:"navi-link"},[_c('div',{staticClass:"navi-icon mr-2",class:item.is_read == true ? 'text-muted' : ''},[(item.action_type)?_c('inline-svg',{attrs:{"src":_vm.getTypeSVG(item.action_type)}}):_c('i',{staticClass:"flaticon2-sms",class:item.is_read == true
                                                    ? 'text-muted'
                                                    : 'text-danger'})],1),_c('div',{staticClass:"navi-text"},[_c('div',{staticClass:"font-weight-normal",class:item.is_read != true
                                                    ? 'font-weight-bold'
                                                    : '',domProps:{"innerHTML":_vm._s(item.message_header)}},[_vm._v(" "+_vm._s(item.message_header)+" ")]),_c('div',{class:item.is_read != true
                                                    ? 'text-danger'
                                                    : 'text-muted'},[_vm._v(" "+_vm._s(item.notification_date)+" "),(item.is_read != true)?_c('span',[_vm._v(" - "),_c('strong',[_vm._v("necitit")])]):_vm._e()])])])])]}),_c('a',{staticClass:"nav-link active",attrs:{"href":"#"},on:{"click":_vm.getMoreNotifications}},[_vm._v(" Incarca mai multe ")])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offcanvas-close mt-n1 pr-2"},[_c('a',{staticClass:"btn btn-xs btn-icon btn-light btn-hover-primary",attrs:{"href":"#","id":"kt_quick_panel_close"}},[_c('i',{staticClass:"ki ki-close icon-xs text-muted"})])])
}]

export { render, staticRenderFns }