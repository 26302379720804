<template>
  <ul class="menu-nav">
    <h5 class="text-dark font-weight-bold my-2 mr-5">
      <router-link
        :to="{ name: 'user', params: { user: user.id } }"
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item mt-1"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <i class="menu-icon flaticon2-mail-1"></i>
          </a>
        </li>
      </router-link>
    </h5>
    <h5 class="text-dark font-weight-bold my-2 mr-5">
      {{ title }}
    </h5>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
