<template>
    <div class="topbar-item mr-1">
        <div
            class="btn btn-new-conv btn-icon btn-dropdown mr-1"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Conversatie noua"
            v-b-modal.new-conv-modal
        >
            <span class="svg-icon svg-icon-xl svg-icon-primary">
                <v-icon>mdi-plus</v-icon>
            </span>
        </div>

        <b-modal
            id="new-conv-modal"
            title="Conversatie noua"
            size="lg"
            hide-backdrop
            hide-footer
            centered
            body-class="new-conv-modal-body"
            modal-class="new-conv-modal"
            :no-enforce-focus="editorFocus"
            @hide="onReset()"
        >
            <b-form id="new-conv-form" @submit="onSubmit" class="p-0">
                <div class="d-block">
                    <div
                        class="d-flex align-items-center border-bottom px-8 min-h-50px"
                        :class="{ 'border-danger text-danger': form.errors.to }"
                    >
                        <div
                            class="fw-bolder w-75px"
                            :class="form.errors.to ? 'text-danger' : 'text-dark'"
                        >
                            To:
                        </div>
                        <v-combobox
                            v-model="form.to"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            hide-no-data
                            chips
                            clearable
                            hide-details
                            hide-selected
                            :item-text="getItemText"
                            label="Cauta client..."
                            flat
                            solo
                        >
                            <template v-slot:selection="{ attr, on, item, selected }">
                                <v-chip
                                    close
                                    @click:close="form.to = ''"
                                    v-bind="attr"
                                    :input-value="selected"
                                    color="white"
                                    v-on="on"
                                >
                                    <v-icon left> mdi-account-circle </v-icon>
                                    <span v-text="getItemEmail(item)"></span>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-list-item-avatar
                                    color="indigo"
                                    class="text-h5 font-weight-light white--text"
                                >
                                    {{ getAvatar(item) }}
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="
                                            (item.firstName ? item.firstName : '') +
                                            ' ' +
                                            (item.lastName ? item.lastName : '')
                                        "
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="item._embedded.emails[0].value"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-action> -->
                            </template>
                        </v-combobox>
                    </div>
                    <div
                        class="align-items-center border-bottom inbox-to-cc pl-8 pr-5 min-h-45px d-flex"
                        :class="{ 'border-danger text-danger': errors.cc }"
                    >
                        <div
                            class="w-75px"
                            :class="errors.cc ? 'text-danger' : 'text-dark-50'"
                        >
                            Cc:
                        </div>
                        <div class="flex-grow-1 email-list-scroll">
                            <v-combobox
                                v-model="form.cc"
                                :items="items"
                                :loading="isLoading"
                                :search-input.sync="search"
                                hide-no-data
                                chips
                                clearable
                                hide-details
                                hide-selected
                                :item-text="getItemText"
                                label="Introdu email Cc"
                                flat
                                multiple
                                solo
                                @focus="errors.cc = false"
                                :delimiters="[',', ' ']"
                                @click:clear="form.errors.cc = []"
                            >
                                <template v-slot:selection="{ attr, on, item, selected }">
                                    <v-chip
                                        close
                                        @click:close="remove(item.id, form.cc, item)"
                                        v-bind="attr"
                                        :input-value="selected"
                                        color="blue-grey"
                                        v-on="on"
                                        :class="{
                                            'email-error': hasError(item, 'cc'),
                                        }"
                                    >
                                        <v-icon left> mdi-account-circle </v-icon>
                                        <span v-text="getItemEmail(item, 'cc')"></span>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar
                                        color="indigo"
                                        class="text-h5 font-weight-light white--text"
                                    >
                                        {{ getAvatar(item) }}
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                (item.firstName ? item.firstName : '') +
                                                ' ' +
                                                (item.lastName ? item.lastName : '')
                                            "
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="item._embedded.emails[0].value"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-combobox>
                        </div>
                    </div>
                    <!--end::CC-->
                    <!--begin::BCC-->
                    <div
                        class="align-items-center border-bottom inbox-to-bcc pl-8 pr-5 min-h-45px d-flex"
                        :class="{ 'border-danger text-danger': errors.bcc }"
                    >
                        <div
                            class="w-75px"
                            :class="errors.bcc ? 'text-danger' : 'text-dark-50'"
                        >
                            Bcc:
                        </div>
                        <div class="flex-grow-1 email-list-scroll">
                            <v-combobox
                                v-model="form.bcc"
                                :items="items"
                                :loading="isLoading"
                                :search-input.sync="search"
                                hide-no-data
                                chips
                                deletable-chips
                                clearable
                                hide-details
                                hide-selected
                                :item-text="getItemText"
                                label="Introdu mail Bcc..."
                                flat
                                multiple
                                :delimiters="[',', ' ']"
                                solo
                                @focus="errors.bcc = false"
                                @click:clear="form.errors.bcc = []"
                            >
                                <template v-slot:selection="{ attr, on, item, selected }">
                                    <v-chip
                                        close
                                        @click:close="remove(item.id, form.bcc, item)"
                                        v-bind="attr"
                                        :input-value="selected"
                                        v-on="on"
                                        :class="{
                                            'email-error': hasError(item, 'bcc'),
                                        }"
                                    >
                                        <v-icon left> mdi-account-circle </v-icon>
                                        <span v-text="getItemEmail(item, 'bcc')"></span>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-list-item-avatar
                                        color="indigo"
                                        class="text-h5 font-weight-light white--text"
                                    >
                                        {{ getAvatar(item) }}
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="
                                                (item.firstName ? item.firstName : '') +
                                                ' ' +
                                                (item.lastName ? item.lastName : '')
                                            "
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="item._embedded.emails[0].value"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-combobox>
                        </div>
                    </div>
                    <!--end::BCC-->
                    <!--begin::Subject-->
                    <div class="border-bottom">
                        <b-form-input
                            id="input-2"
                            v-model="form.subject"
                            placeholder="Subiect email"
                            class="form-control form-control-transparent border-0 px-8 min-h-45px"
                            required
                        ></b-form-input>
                    </div>
                    <!--end::Subject-->
                    <div
                        class="px-4 border-bottom d-flex justify-content-between align-items-center"
                    >
                        <b-form-select
                            v-model="form.selected"
                            id="input-3"
                            class="border-0 w-50 d-inline"
                            required
                            placeholder="Departament"
                            :options="options"
                        ></b-form-select>
                        <div class="ml-2">
                            <span
                                @click="
                                    form.schedule.showScheduleConv = !form.schedule
                                        .showScheduleConv
                                "
                                class="fs-bold cursor-pointer text-hover-primary"
                                :class="
                                    form.schedule.showScheduleConv
                                        ? 'text-primary'
                                        : 'text-muted'
                                "
                                >Conversatie programata</span
                            >
                        </div>
                    </div>
                    <DateTimePicker v-model="form.schedule" />
                    <!--begin::Message-->
                    <div class="border-bottom">
                        <div id="new-conv-form_editor" class="bg-transparent border-0">
                            <FormEditor
                                v-model="form.text"
                                :focus="editorFocus"
                                @changeFocus="changeFocus"
                            />
                        </div>
                    </div>
                    <!--end::Message-->
                </div>
                <!--begin::Attachments-->

                <AttachmentZone
                    :attachments="getAttachments"
                    :dropzoneShow="getDropzoneShow"
                    @setAttachments="setAttachments"
                />
                <!--end::Attachments-->
                <SavedReply @insert="setContent" :id="'saved-replies-new-conv'" />
                <div
                    class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-top"
                >
                    <!--begin::Actions-->
                    <div class="d-flex align-items-center mr-3">
                        <!--begin::Send-->
                        <div class="btn-group mr-4">
                            <button
                                class="btn btn-primary font-weight-bold px-6"
                                type="submit"
                            >
                                Trimite<span
                                    class="svg-icon svg-icon-md svg-icon-white ml-3"
                                >
                                    <inline-svg
                                        src="/media/svg/icons/Communication/Send.svg"
                                    ></inline-svg>
                                </span>
                            </button>
                        </div>

                        <!--end::Send-->
                        <!--begin::Other-->
                        <span
                            class="btn btn-icon btn-sm btn-clean mr-2"
                            id="new-conv-form_attachments_select"
                            @click="dropzoneShow = !dropzoneShow"
                        >
                            <i class="flaticon2-clip-symbol"></i>
                        </span>

                        <span
                            v-show="form.selected"
                            class="btn btn-icon btn-sm btn-clean mr-2"
                            id="kt_inbox_reply_saved"
                            @click="openSavedReplies"
                        >
                            <i class="flaticon2-medical-records"></i>
                        </span>
                        <!--end::Other-->
                    </div>
                    <!--end::Actions-->
                    <!--begin::Toolbar-->
                    <div class="d-flex align-items-center">
                        <span class="btn btn-icon btn-sm btn-clean" @click="onReset">
                            <i class="flaticon2-refresh-arrow"></i>
                        </span>
                        <span
                            class="btn btn-icon btn-sm btn-clean"
                            @click="hideModal(true)"
                        >
                            <i class="flaticon2-rubbish-bin-delete-button"></i>
                        </span>
                    </div>
                    <!--end::Toolbar-->
                </div>
            </b-form>

            <template #modal-footer>
                <b-button type="submit" form="new-conv-form" size="sm" variant="primary">
                    Trimite
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AttachmentZone from "@/view/pages/conversation/conversation-comp/AttachmentZone";
import moment from "moment";
import DateTimePicker from "@/view/content/DateTimePicker";
import SavedReply from "@/view/pages/conversation/SavedReply";
import FormEditor from "@/view/content/form/FormEditor";

export default {
    data() {
        return {
            form: {
                to: "",
                cc: [],
                bcc: [],
                checked: [],
                subject: "",
                text: "Buna ziua,<br/><br/>",
                selected: null,
                schedule: {
                    date: "",
                    time: "",
                    showScheduleConv: false,
                },
                errors: {
                    to: false,
                    cc: [],
                    bcc: [],
                },
            },
            errors: {
                cc: false,
                bcc: false,
            },
            show: true,
            options: [{ value: null, text: "Alege un departament*", disabled: true }],
            dropzoneShow: false,
            attachments: [],
            isLoading: false,
            items: [],
            model: null,
            search: null,
            showSavedReply: false,
            editorFocus: false,
        };
    },
    components: { FormEditor, AttachmentZone, DateTimePicker, SavedReply },
    watch: {
        search(val) {
            // Items have already been loaded
            // if (this.items.length > 0) return;
            if (val === "") return;

            this.isLoading = true;

            // Lazily load input items

            this.$store
                .dispatch("mails/searchCustomers", val)
                .then((res) => {
                    this.items = res;
                })
                .finally(() => (this.isLoading = false));
        },
        forwardMail() {
            // var str = this.forwardMail.replace(/<div/g, "<p");
            this.form.text = this.forwardMail.text
                ? this.forwardMail.text
                : "Buna ziua,<br/><br/>";
            this.attachments = this.forwardMail.attachments
                ? this.forwardMail.attachments
                : [];
            // this.form.text = str.replace(/<\/div>/g, "</p>");
        },
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();

            let cc = [];
            this.form.cc.map((elem) => {
                const email = this.getItemEmail(elem, "cc").trim();
                if (this.validateEmail(email) === null) {
                    this.form.errors.cc.push(elem);
                    this.errors.cc = true;
                }
                cc.push(email);
            });

            let bcc = [];
            this.form.bcc.map((elem) => {
                const email = this.getItemEmail(elem, "bcc").trim();
                if (this.validateEmail(email) === null) {
                    this.form.errors.bcc.push(elem);
                    this.errors.bcc = true;
                }
                bcc.push(email);
            });

            let toEmail = false;
            if (this.form.to) {
                if (this.form.to._embedded) {
                    toEmail = this.form.to._embedded.emails[0].value;
                } else {
                    toEmail = this.form.to;
                }
            }
            if (this.validateEmail(toEmail)) {
                this.form.errors.to = false;
            } else {
                toEmail = false;
                this.form.errors.to = true;
            }

            if (toEmail && !this.errors.bcc && !this.errors.cc) {
                let payload = {
                    type: "email",
                    mailboxId: this.form.selected,
                    subject: this.form.subject,
                    assignTo: this.user.id,
                    customer: {
                        email: toEmail,
                    },
                    threads: [
                        {
                            text: this.form.text,
                            type: "message",
                            user: this.user.id,
                            attachments: this.attachments,
                            cc: cc,
                            bcc: bcc,
                        },
                    ],
                };

                if (
                    this.form.schedule.time === "" &&
                    this.form.schedule.showScheduleConv
                ) {
                    if (moment(this.form.schedule.date).isSame(moment(), "day")) {
                        this.form.schedule.time = moment().format("HH:mm:ss");
                    } else {
                        this.form.schedule.time = "07:00:00";
                    }
                }

                let datetimepicker =
                    this.form.schedule.date + " " + this.form.schedule.time;

                if (datetimepicker.length > 1) {
                    payload.threads[0].sl_schedule_date = datetimepicker;
                }

                this.$store
                    .dispatch("mails/createConversation", payload)
                    .then((response) => {
                        if (response._embedded) {
                            let alert = {
                                message_header:
                                    response._embedded.threads.messages[0].action.text,
                                message_content:
                                    response._embedded.threads.messages[0].preview,
                                notification_date:
                                    response._embedded.threads.messages[0].createAt,
                            };

                            this.$store.commit("alerts/setNewAlert", alert);
                        }
                        this.$bvModal.hide("new-conv-modal");
                        this.onReset();
                    });
            }
        },
        onReset() {
            // Reset our form values
            this.form.to = "";
            this.form.cc = [];
            this.form.bcc = [];
            this.form.subject = "";
            this.form.text = "Buna ziua,<br/><br/>";
            this.form.selected = null;
            this.form.schedule.schedule_date = "";
            this.form.schedule.schedule_time = "";
            this.form.schedule.showScheduleConv = false;
            this.form.errors.bcc = [];
            this.form.errors.cc = [];
            // Trick to reset/clear native browser form validation state
            this.$store.dispatch("mails/forwardMail", {});
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
            this.attachments = [];
            this.items = [];
        },
        hideModal(clear = false) {
            if (clear) {
                this.onReset();
            }
            this.$bvModal.hide("new-conv-modal");
        },
        setAttachments(attachments) {
            this.attachments = attachments;
        },
        getItemText(item) {
            return `${item.firstName} ${item.lastName} ${item._embedded.emails[0].value}`;
        },
        hasError(item, type) {
            if (type === "cc") return this.form.errors.cc.includes(item);
            if (type === "bcc") return this.form.errors.bcc.includes(item);
            return false;
        },
        getItemEmail(item, type = "to") {
            let email = false;
            if (item) {
                if (item._embedded) {
                    email = item._embedded.emails[0].value;
                } else {
                    email = item;

                    if (item.value !== "" || !item.value) {
                        let splitEmail = item.split(", ");
                        if (splitEmail.length > 1) {
                            splitEmail.forEach((dep) => {
                                if (type == "to") {
                                    this.form[type] = dep;
                                } else if (dep !== "") {
                                    this.form[type].push(dep);
                                }
                            });

                            if (type !== "to") {
                                this.form[type].splice(this.form[type].indexOf(item), 1);
                            }
                        }
                    }
                }
            }
            // if (this.validateEmail(email)) {
            //     this.form.errors[type] = false;
            // } else {
            //     this.form.errors[type] = true;
            // }
            return email;
        },
        getAvatar(item) {
            if (item.photoUrl) return photoUrl;

            if (item.firstName && item.lastName) {
                return (item.firstName.charAt(0) + item.lastName.charAt(0)).toUpperCase();
            }

            return item._embedded.emails[0].value.charAt(0).toUpperCase();
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        remove(id, from, item = null) {
            let idx = -1;

            if (id) {
                idx = from
                    .map((email) => {
                        return email.id;
                    })
                    .indexOf(id);
            }

            if (id === undefined) {
                if (!item) return;
                idx = from.indexOf(item);
            }
            from.splice(idx, 1);
            from = [...from];
        },
        setContent(payload) {
            this.form.text = payload.text;
        },
        openSavedReplies() {
            let payload = {
                id: this.form.selected,
            };
            this.$store.dispatch("mails/getSavedReplies", payload).then(() => {
                this.$bvModal.show("saved-replies-new-conv");
            });
        },
        changeFocus(value) {
            this.editorFocus = value;
        },
    },
    computed: {
        getAttachments() {
            return this.attachments;
        },
        getDropzoneShow() {
            return this.dropzoneShow;
        },
        ...mapGetters("departments", ["eachDepartments", "departments"]),
        ...mapGetters(["user"]),
        forwardMail() {
            return this.$store.getters["mails/forwardMail"];
        },
    },
    created() {
        this.departments.forEach((dep) => {
            this.options.push({ value: dep.id, text: dep.name });
        });
    },
    destroyed() {
        this.onReset();
    },
};
</script>

<style lang="scss" scoped>
.btn-new-conv {
    background-color: #f3c200 !important;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}
</style>

<style>
.email-error {
    background-color: #f64e60 !important;
}

.email-error span {
    color: #fff;
}

.email-list-scroll {
    max-height: 250px;
    overflow: auto;
}

.new-conv-modal-body {
    padding: 0 !important;
}

.new-conv-modal {
    max-width: 650px;
    height: auto;
    position: fixed;
    left: auto;
    top: auto;
    bottom: 25px;
    right: 25px;
    margin: 0;
    -webkit-box-shadow: 0px 0px 60px -15px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 60px -15px rgb(0 0 0 / 20%);
    border-radius: 0.42rem;
}

div .ql-container.ql-snow,
div .ql-toolbar.ql-snow {
    border: transparent;
}

div .ql-toolbar.ql-snow {
    border-bottom: 1px solid;
    border-bottom-color: rgb(235, 237, 243) !important;
}

#new-conv-modal {
    max-height: 95%;
    overflow: auto;
}

#new-conv-modal .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
}
</style>
