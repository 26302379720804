<template>
  <div class="topbar-item mr-1">
    <input
      v-on:keyup="onSearch"
      type="text"
      class="form-control quick-search-input"
      placeholder="Cauta..."
      :value="inputValue"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onSearch(event) {
      if (event.target.value.length > 2) {
        this.$store.dispatch("mails/search", event.target.value);
        this.$store.dispatch("mails/filterMails", true);
        // this.loading = true;
        // // simulate getting search result
        // setTimeout(() => {
        //   // this.data = this.result;
        //   this.loading = false;
        //     this.$router.push('/search-list');
        // }, 2000)
      } else {
        this.$store.dispatch("mails/filterMails", false);
      }
    },
  },
  computed: {
    inputValue() {
      return this.$store.getters["mails/inputSearchValue"];
    },
  },
  destroyed() {
    this.$store.dispatch("mails/search", "");
  },
};
</script>
