<template>
    <div></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            counter: 0,
        };
    },
    components: {},
    methods: {
        ...mapActions("alerts", ["markAsRead"]),
        stripHtml(value) {
            const div = document.createElement("div");
            div.innerHTML = value;
            const text = div.textContent || div.innerText || "";
            return text;
        },
        showToast(value) {
            // Use a shorter name for this.$createElement
            const h = this.$createElement;
            // Increment the toast count
            this.count++;

            if (value.message_content) {
                // Create the title
                var vNodesTitle = h(
                    "div",
                    { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
                    [
                        h("strong", { class: "mr-2" }, value.message_header),
                        h(
                            "small",
                            { class: "ml-auto text-italics" },
                            value.notification_date
                        ),
                    ]
                );
                // Create the message
                var vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                    this.stripHtml(value.message_content),
                ]);
            } else {
                var vNodesTitle = h(
                    "div",
                    { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
                    [
                        h("strong", { class: "mr-2" }, "Aveți o notificare nouă!"),
                        h(
                            "small",
                            { class: "ml-auto text-italics" },
                            value.notification_date
                        ),
                    ]
                ); // Create the message
                var vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                    this.stripHtml(value.message_header),
                ]);
            }
            var variant = "info";
            if (value.variant) {
                variant = value.variant;
            }
            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: variant,
                toaster: "b-toaster-top-right",
            });
        },
    },
    mounted() {},
    watch: {
        "$store.state.alerts.alerts": function () {
            var alerts = this.$store.state.alerts.alerts;
            if (alerts) {
                alerts.forEach((element) => {
                    this.showToast(element);
                    this.markAsRead(element.conversation_id);
                });
            }
        },
    },
};
</script>
