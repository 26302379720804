<template>
    <ul class="menu-nav">
        <router-link
            :to="{ name: 'user', params: { user: user.id } }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-mail-1"></i>
                    <span class="menu-text">Dashboard</span>
                </a>
            </li>
        </router-link>
        <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a href="https://octav.pefoc.ro/admin/orders" class="menu-link">
                <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
                <i class="menu-icon far flaticon2-shopping-cart"></i>
                <span class="menu-text">Portal</span>
            </a>
        </li>
        <router-link
            :to="{ name: 'UsersList' }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="user.permissions && user.permissions.can_manage_users"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-user-1"></i>
                    <span class="menu-text">Utilizatori</span>
                </a>
            </li>
        </router-link>
        <router-link
            :to="{ name: 'messages' }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="user.permissions && user.permissions.can_manage_users"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-send-1"></i>
                    <span class="menu-text">Mesaje utilizatori</span>
                </a>
            </li>
        </router-link>
        <router-link
            :to="{ name: 'contacts' }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon fas fa-at"></i>
                    <span class="menu-text">Contacte</span>
                </a>
            </li>
        </router-link>
        <router-link
            :to="{ name: 'SavedReplies', params: { department: 1 } }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="user.permissions && user.permissions.can_manage_users"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-medical-records"></i>
                    <span class="menu-text">Conversatii predefinite</span>
                </a>
            </li>
        </router-link>
        <router-link
            :to="{ name: 'Workflows' }"
            custom
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="user.permissions && user.permissions.can_manage_users"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon fas fa-filter"></i>
                    <span class="menu-text">Workflows</span>
                </a>
            </li>
        </router-link>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "KTMenu",
    computed: {
        ...mapGetters(["user"]),
        dashboardUrl() {
            return "/departments/user/" + this.user.id;
        },
    },
    methods: {
        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1;
        },
    },
};
</script>
