<template>
    <b-modal
        id="notifyagents"
        title="Mesaje de la manager"
        hide-footer
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
    >
        <div v-if="notifications">
            <TransitionGroup name="list" tag="ul">
                <b-card
                    v-for="notification in notifications"
                    :key="notification.id"
                    border-variant="dark"
                    class="mb-2"
                    body-class="p-4"
                    :title="'Mesaj #' + notification.id + ' de la ' + notification.author"
                    title-tag="h5"
                >
                    <b-card-text>{{ notification.message }}</b-card-text>
                    <div class="text-right">
                        <b-button
                            @click="confirm(notification)"
                            variant="primary"
                            size="sm"
                            >Confirma</b-button
                        >
                    </div>
                </b-card>
            </TransitionGroup>

            <!-- <div v-for="notification in notifications" :key="notification.id">
                {{ notification.message }}
            </div> -->
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            // notifications: [],
        };
    },
    watch: {
        notifications() {
            if (this.notifications.length) {
                this.$bvModal.show("notifyagents");
            }
            this.notifications.map((notification) => {
                if (!notification.read_at) {
                    this.$store.dispatch("messages/markAsRead", notification.id);
                }
            });
        },
    },
    computed: {
        notifications() {
            // console.log(this.$store.getters["notifications/getAgentNotifications"]);
            return this.$store.getters["notifications/getAgentNotifications"];
        },
    },
    methods: {
        confirm(message) {
            // console.log(message);
            const i = this.notifications.indexOf(message);
            if (i > -1) {
                this.notifications.splice(i, 1);
            }

            this.$store.dispatch("messages/markAsConfirm", message.id).then(() => {
                if (!this.notifications.length) {
                    this.$bvModal.hide("notifyagents");
                }
            });
        },
    },
    mounted() {},
    // created() {
    //     this.notifications = this.$store.getters["notifications/getAgentNotifications"];
    // },
};
</script>

<style>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
