var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":{ name: 'user', params: { user: _vm.user.id } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-mail-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),_vm._m(0),(_vm.user.permissions && _vm.user.permissions.can_manage_users)?_c('router-link',{attrs:{"to":{ name: 'UsersList' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Utilizatori")])])])]}}],null,false,2055997565)}):_vm._e(),(_vm.user.permissions && _vm.user.permissions.can_manage_users)?_c('router-link',{attrs:{"to":{ name: 'messages' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-send-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Mesaje utilizatori")])])])]}}],null,false,1354028773)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'contacts' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-at"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contacte")])])])]}}])}),(_vm.user.permissions && _vm.user.permissions.can_manage_users)?_c('router-link',{attrs:{"to":{ name: 'SavedReplies', params: { department: 1 } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-medical-records"}),_c('span',{staticClass:"menu-text"},[_vm._v("Conversatii predefinite")])])])]}}],null,false,3433202244)}):_vm._e(),(_vm.user.permissions && _vm.user.permissions.can_manage_users)?_c('router-link',{attrs:{"to":{ name: 'Workflows' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-filter"}),_c('span',{staticClass:"menu-text"},[_vm._v("Workflows")])])])]}}],null,false,2834902291)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"https://octav.pefoc.ro/admin/orders"}},[_c('i',{staticClass:"menu-icon far flaticon2-shopping-cart"}),_c('span',{staticClass:"menu-text"},[_vm._v("Portal")])])])
}]

export { render, staticRenderFns }