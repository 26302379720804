<template>
    <!-- <vue-editor
                                v-model="form.text"
                                :editor-toolbar="customToolbar"
                            /> -->
    <vue-ckeditor
        v-model="text"
        :config="config"
        @focus="toggleEditorFocus(true)"
        @blur="toggleEditorFocus(false)"
    />
</template>

<script>
import VueCkeditor from "vue-ckeditor2";
// import { VueEditor, Quill } from "vue2-editor";

export default {
    data() {
        return {
            customToolbar: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                ["link"],
                ["clean"],
            ],
            config: {
                versionCheck: false,
                height: 300,
                removePlugins: "exportpdf",
                // format_tags: "p;h3;div",
                allowedContent: true,
                toolbarGroups: [
                    { name: "styles", groups: ["styles"] },

                    { name: "basicstyles", groups: ["basicstyles"] },
                    { name: "colors" },
                    {
                        name: "paragraph",
                        groups: [
                            "list",
                            "indent",
                            "blocks",
                            "align",
                            "bidi",
                            "paragraph",
                        ],
                    },
                    { name: "links", groups: ["links"] },
                    { name: "basicstyles", groups: ["cleanup"] },
                    { name: "insert", groups: ["insert"] },
                ],
                removeButtons:
                    "Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll," +
                    "Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,CopyFormatting,Outdent," +
                    "Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Unlink," +
                    "Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Font,Maximize,ShowBlocks,About,FontSize",
            },
        };
    },
    props: {
        value: String,
        focus: Boolean,
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        focusEditor: {
            get() {
                return this.focus;
            },
            set(value) {
                this.$emit("changeFocus", value);
            },
        },
    },
    components: { VueCkeditor },
    methods: {
        toggleEditorFocus(val = !this.focusEditor) {
            setTimeout(() => {
                this.focusEditor = val;
            }, 10);
        },
    },
};
</script>
