<template>
    <div class="topbar-item">
        <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
            <span class="svg-icon svg-icon-xl svg-icon-danger">
                <!--begin::Svg Icon | path: /media/svg/icons/General/Notifications1.svg-->
                <span
                    class="svg-icon svg-icon-2hx"
                    :class="getUnreadCount > 0 ? 'svg-icon' : 'svg-icon-muted'"
                >
                    <inline-svg
                        src="/media/svg/icons/General/Notifications1.svg"
                    ></inline-svg>
                </span>
                <!--end::Svg Icon-->
            </span>
        </div>

        <!-- begin::Quick Panel -->
        <div
            id="kt_quick_panel"
            ref="kt_quick_panel"
            class="offcanvas offcanvas-right pt-5 pb-10"
            style="overflow: hidden"
        >
            <!--begin::Header-->
            <div
                class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5"
            >
                <ul
                    class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10 justify-content-between"
                    role="tablist"
                >
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            data-tab="1"
                            data-toggle="tab"
                            href="#"
                            role="tab"
                            aria-selected="false"
                        >
                            Notificari
                            <span v-if="getUnreadCount > 0">({{ getUnreadCount }})</span>
                        </a>
                    </li>
                    <span v-if="getUnreadCount > 0" class="nav-item"
                        ><a @click="markAllAsRead" class="nav-link"
                            >Marcheaza toate ca citite</a
                        ></span
                    >
                </ul>
                <div class="offcanvas-close mt-n1 pr-2">
                    <a
                        href="#"
                        class="btn btn-xs btn-icon btn-light btn-hover-primary"
                        id="kt_quick_panel_close"
                    >
                        <i class="ki ki-close icon-xs text-muted"></i>
                    </a>
                </div>
            </div>
            <!--end::Header-->

            <div class="offcanvas-content px-10">
                <div class="tab-content">
                    <div class="scroll pr-7 mr-n7" id="kt_quick_panel_notifications">
                        <!--begin::Nav-->
                        <div class="navi navi-icon-circle navi-spacer-x-0">
                            <template v-for="(item, i) in getNotifications">
                                <!--begin::Item-->
                                <!-- <a href="#" class="navi-item" v-bind:key="i"> -->
                                <router-link
                                    :to="{
                                        name: 'conversation',
                                        params: { id: item.conversation_id },
                                    }"
                                    @click.native="markAsRead(item.conversation_id)"
                                    class="navi-item"
                                    v-bind:key="i"
                                >
                                    <div class="navi-link">
                                        <div
                                            class="navi-icon mr-2"
                                            :class="
                                                item.is_read == true ? 'text-muted' : ''
                                            "
                                        >
                                            <inline-svg
                                                v-if="item.action_type"
                                                :src="getTypeSVG(item.action_type)"
                                            />
                                            <i
                                                v-else
                                                class="flaticon2-sms"
                                                :class="
                                                    item.is_read == true
                                                        ? 'text-muted'
                                                        : 'text-danger'
                                                "
                                            ></i>
                                        </div>
                                        <div class="navi-text">
                                            <div
                                                class="font-weight-normal"
                                                :class="
                                                    item.is_read != true
                                                        ? 'font-weight-bold'
                                                        : ''
                                                "
                                                v-html="item.message_header"
                                            >
                                                {{ item.message_header }}
                                            </div>
                                            <div
                                                :class="
                                                    item.is_read != true
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                            >
                                                {{ item.notification_date }}
                                                <span v-if="item.is_read != true">
                                                    - <strong>necitit</strong></span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </a> -->
                                </router-link>
                                <!--end::Item-->
                            </template>
                            <a
                                class="nav-link active"
                                href="#"
                                v-on:click="getMoreNotifications"
                            >
                                Incarca mai multe
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end::Quick Panel -->
    </div>
</template>

<script>
import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "KTQuickPanel",
    data() {
        return {
            tabIndex: 0,
            timer: null,
        };
    },
    mounted() {
        // console.log(this.getNotifications);
        // Init Quick Offcanvas Panel
        KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
        if (this.isAuthenticated) {
            this.getNewNotifications();
            // this.timer = window.setInterval(() => {
            // this.getNewestNotifications();
            // }, 30000);
            this.customSetInterval();
        }
    },
    methods: {
        ...mapActions("notifications", [
            "getNewNotifications",
            "incrementPage",
            "getNewestNotifications",
            "markAsRead",
            "markAllAsRead",
        ]),

        getMoreNotifications() {
            this.incrementPage();
            this.getNewNotifications();
        },
        getTypeSVG(type) {
            return this.layoutConfig("actions." + type);
        },
        customSetInterval() {
            this.timer = window.setInterval(() => {
                this.getNewestNotifications();
                clearInterval(this.timer);
                this.customSetInterval();
            }, 30000);
        },
    },
    computed: {
        ...mapGetters("notifications", ["getNotifications", "getPage", "getUnreadCount"]),
        ...mapGetters(["isAuthenticated"]),
        ...mapGetters(["layoutConfig"]),
    },
    unmount() {
        clearInterval(this.timer);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
