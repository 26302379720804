<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ user.firstName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img
          v-if="user.photoUrl != 'https://sharedmailapi.pefoc.ro/img/default-avatar.png'"
          alt="Pic"
          :src="user.photoUrl"
        />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ user.firstName.charAt(0) + user.lastName.charAt(0) }}
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Profil Utilizator
          <small class="text-muted font-size-sm ml-2"
            >{{ user.conversationsCount }} mailuri asignate</small
          >
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="user.photoUrl" alt="" />
            <i
              class="symbol-badge"
              :class="[
                user.status === 'active' ? 'active-bullet' : '',
                user.status === 'vacation' ? 'vacation-bullet' : '',
                user.status === 'busy' ? 'busy-bullet' : '',
              ]"
            ></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >{{ user.firstName }} {{ user.lastName }}
            </router-link>
            <div class="text-muted mt-1">
              {{ user.jobTitle }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ user.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <div class="navi-item">
            <div class="font-weight-bold">Status utilizator</div>
            <div class="navi-link">
              <div class="change-status-wrapper">
                <v-select
                  v-model="selected"
                  :items="options"
                  @change="changeUserStatus()"
                  menu-props="auto"
                  dense
                  outlined
                >
                  <template v-slot:item="{ status, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ status }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer></v-spacer>
                            <v-chip text-color="white" :color="item.color" small></v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> </template
                ></v-select>

                <!-- <div class="mt-3">
                  Selected: <strong>{{ selected }}</strong>
                </div> -->
              </div>
            </div>
          </div>
          <!--end:Item-->
          <div class="separator separator-dashed my-7"></div>
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user,
.change-status-wrapper {
  overflow: hidden;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Selecteaza status", disabled: true },
        { value: "active", text: "Disponibil", color: "#1BC5BD" },
        { value: "busy", text: "Ocupat", color: "#F3C200" },
        { value: "vacation", text: "Concediu", color: "#F64E60" },
      ],
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.selected = this.user.status;
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "login" });
        this.$store.dispatch("departments/resetDepartments");
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    changeUserStatus() {
      let status = this.selected;
      const userId = this.user.id;
      this.$store.dispatch("users/sendUserStatus", { userId, status });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["user"]),
    ...mapGetters("users", ["userStatus"]),
    ...mapGetters("users", ["userId"]),
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
</style>
